<template>
  <div class="form">
    <h2 class="form-header">{{ headertext }}</h2>
    <h3>{{ subheadertext }}</h3>
    <div class="form-row">
      <p>Name:</p>
      <input placeholder="Name:" id="name" />
    </div>
    <div class="form-row-address">
      <p>Address:</p>
      <div class="form-row-address-container">
        <div class="form-row-address-first">
          <input placeholder="Street:" id="street" />
        </div>
        <div class="form-row-address-second">
          <input placeholder="City:" ref="city" id="city" />
          <select placeholder="State:" ref="state" id="state">
            <option disabled selected>State:</option>
            <option v-for="state in stateList" :key="state">{{ state }}</option>
          </select>
          <input placeholder="Zipcode:" ref="zipcode" id="zipcode" />
        </div>
      </div>
    </div>
    <p class="disclaimer"></p>
    <div class="form-row email-mobile-row">
      <p>Email:</p>
      <input placeholder="Email:" ref="email" id="email" />
    </div>
    <div class="form-row">
      <p>Phone:</p>
      <input placeholder="Phone:" ref="phone" id="phone" />
    </div>
    <div class="form-row">
      <p>Age:</p>
      <input placeholder="Age:" ref="age" id="age" />
    </div>
    <div class="form-checkbox">
      <input type="checkbox" id="PokerGO" checked="true" />
      <label for="PokerGO">Sign-up for PokerGO special offers &#38; news</label>
    </div>
    <vue-recaptcha
      ref="recaptcha"
      :sitekey="recaptchakey"
      @verify="setRobot()"
    />

    <button id="submitButton" class="form-submit" @click="submit">
      ENTER TO WIN
    </button>
    <img src="../assets/loader.gif" class="form-loading" id="formLoading" />
    <p class="form-success" id="formSuccess">Successfully Submitted</p>
    <p class="form-error" id="formError">
      Submission not successful! Try again
    </p>
    <p class="form-error" id="formSpecificErrorName">Please enter your name</p>
    <p class="form-error" id="formSpecificErrorAddress">
      Please enter your full address
    </p>
    <p class="form-error" id="formSpecificErrorPhone">
      Please enter your phone number
    </p>
    <p class="form-error" id="formSpecificErrorEmail">
      Please enter your email address
    </p>
    <p class="form-error" id="formSpecificErrorAge">Please enter your age</p>
  </div>
</template>

<script>
import states from "../assets/states.js";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  components: {
    VueRecaptcha,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      recaptchakey: process.env.RECAPTCHA,
      headertext: "",
      subheadertext: "",
      stateList: states,
      robot: false,
    };
  },
  methods: {
    setRobot(response) {
      console.log(response);
      this.robot = true;
    },

    loading() {
      document.getElementById("formLoading").style.display = "block";
      // this.$refs.recaptcha.execute();
      document.getElementById("submitButton").style.display = "none";
      document.getElementById("formError").style.display = "none";
      document.getElementById("formSuccess").style.display = "none";
      document.getElementById("formSpecificErrorName").style.display = "none";
      document.getElementById("formSpecificErrorEmail").style.display = "none";
      document.getElementById("formSpecificErrorAddress").style.display =
        "none";
      document.getElementById("formSpecificErrorPhone").style.display = "none";
      document.getElementById("formSpecificErrorAge").style.display = "none";
    },
    success() {
      document.getElementById("formLoading").style.display = "none";
      document.getElementById("formError").style.display = "none";
      document.getElementById("formSuccess").style.display = "block";
    },
    error() {
      document.getElementById("formLoading").style.display = "none";
      document.getElementById("formError").style.display = "block";
    },
    specificError(errorType) {
      document.getElementById("formLoading").style.display = "none";
      document.getElementById(`formSpecificError${errorType}`).style.display =
        "block";
      document.getElementById(`submitButton`).style.display = "block";
    },
    async submit() {
      if (!this.robot) {
        return;
      }
      this.loading();
      //await this.$refs.recaptcha.execute();
      // const token = await this.$recaptcha("submit");
      // console.log("token: ", token);
      if (!document.getElementById("name").value) {
        this.specificError("Name");
        return;
      } else if (!document.getElementById("age").value) {
        this.specificError("Age");
        return;
      } else if (
        !document.getElementById("street").value ||
        !document.getElementById("city").value ||
        !document.getElementById("state").value ||
        !document.getElementById("zipcode").value
      ) {
        this.specificError("Address");
        return;
      } else if (!document.getElementById("phone").value) {
        this.specificError("Phone");
        return;
      } else if (!document.getElementById("email").value) {
        this.specificError("Email");
        return;
      }

      const postStream = {
        name: document.getElementById("name").value,
        address: `${document.getElementById("street").value} ${
          document.getElementById("city").value
        }, ${document.getElementById("state").value} ${
          document.getElementById("zipcode").value
        }`,
        email: document.getElementById("email").value,
        phone: document.getElementById("phone").value,
        age: document.getElementById("age").value,
        pokergo: document.getElementById("PokerGO").checked,
        contest_name: process.env.SITE,
        recorded: new Date(),
      };

      console.log("stream: ", postStream);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postStream),
      };
      fetch(
        "https://764678f89d6669ff7afb32955976bda7.m.pipedream.net",
        requestOptions
      )
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            this.error();
            return Promise.reject(error);
          }
          this.success();
          this.postId = data.id;
          let subject = "";
          let templateId = "";
          switch (this.site) {
            case "rungood":
              subject = process.env.EMAIL_RUNGOOD_SUBJECT;
              templateId = process.env.EMAIL_RUNGOOD_TEMPLATE_ID;
              break;
            case "hsp":
              subject = process.env.EMAIL_HSP_SUBJECT;
              templateId = process.env.EMAIL_HSP_TEMPLATE_ID;
              break;
          }
          var message = {
            app_id: process.env.ONESIGNAL_APP_ID,
            include_email_tokens: [postStream.email],
            email_subject: subject,
            template_id: templateId,
          };
          this.sendNotification(message);
        })
        .catch((error) => {
          this.errorMessage = error;
          this.error();
        });
    },
    sendNotification(data) {
      var headers = {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Basic " + process.env.ONESIGNAL_REST_API_KEY,
      };

      var options = {
        host: "onesignal.com",
        port: 443,
        path: "/api/v1/notifications",
        method: "POST",
        headers: headers,
      };

      var https = require("https");
      var req = https.request(options, function (res) {
        res.on("data", function (data) {
          console.log("Response:");
          console.log(JSON.parse(data));
        });
      });
      req.on("error", function (e) {
        console.log("ERROR:");
        console.log(e);
      });
      req.write(JSON.stringify(data));
      req.end();
    },
  },
  mounted() {
    console.log(this.recaptchakey);
    switch (this.site) {
      case "rungood":
        this.headertext =
          "Complete all fields for a chance to win a seat into the 2022 RGPS: All-Stars ProAM";
        this.subheadertext = "";
        break;
      case "hsp":
        this.headertext =
          "Complete all fields for a chance to win one of 14 High Stakes Poker prizes.";
        this.subheadertext =
          "Prizes include: Signed and framed group photos (5) with signed decks of cards, signed and framed photos of Doyle Brunson, Jennifer Tilly, signed and framed photos with signed felt of Doyle Brunson, Patrik Antonius, Daniel Negreanu, Phil Ivey, Garrett Adelstein, and Tom Dwan.";
        break;
    }
    console.log("header text:", this.headertext);
  },
};
</script>
