<template>
  <div class="">
    <FormNft :site="site" v-if="site == 'NFT_WHITELIST'"/>
    <Form :site="site" v-else/>
    <p class="home-legal" v-if="site !== 'NFT_WHITELIST'">
      NO PURCHASE NECESSARY. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE
      YOUR CHANCES OF WINNING. Void in Rhode Island, New York, Florida, and
      where prohibited by law. U.S. law governs. 21+ U.S. legal residents only
      (except in Rhode Island, New York and Florida). Eligibility restrictions
      apply. Sponsored by Poker Central LLC.
      <router-link :to="rules">Official Rules Available Here</router-link>
    </p>
  </div>
</template>

<script>
import Form from "./Form.vue";
import FormNft from "./FormNft.vue"
export default {
  components: {
    Form,
    FormNft
  },
  data() {
    return {
      rules: "",
      site: process.env.SITE,
    };
  },
  mounted() {
    console.log("SITE LOADED:", this.site);
    if (process.env.SITE === "rungood") {
      this.rules = "/rulesrungood";
    } else if (process.env.SITE === "hsp") {
      this.rules = "/ruleshsp";
    }
  },
};
</script>
