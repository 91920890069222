<template>
  <div class="form">
    <h2 class="form-header">{{ headertext }}</h2>
    <h3>{{ subheadertext }}</h3>
    <!-- <div class="form-row">
      <p>PokerGO  Email (required):</p>
      <input placeholder="PokerGO Email (required):" id="email" />
    </div> -->
    
    <div v-if="formsubmitted">
      <h1 class="form-header">Thank you for submitting</h1>
    </div>
    <div v-if="!formsubmitted  && !formclosed">
      <div class="form-row">
        <p>PokerGO Acc. Email (required):</p>
        <input
          placeholder="PokerGO Account Email (required):"
          v-model="email"
        />
      </div>
      <div class="form-row">
        <p>Ethereum Wallet ID (required)</p>
        <input
          placeholder="Ethereum Wallet ID (NO ENS) (required):"
          v-model="ethwallet"
        />
      </div>
      <!-- <p class="disclaimer"></p> -->
      <div class="form-row email-mobile-row">
        <p>Discord Username (optional):</p>
        <input
          placeholder="Discord Username (optional):"
          ref="discord"
          v-model="discord"
        />
      </div>
      <div class="form-row">
        <p>Twitter Username (optional):</p>
        <input
          placeholder="Twitter Username (optional):"
          ref="twitter"
          v-model="twitter"
        />
      </div>
      <!-- <div class="form-checkbox">
      <input type="checkbox" id="PokerGO" checked="true" />
      <label for="PokerGO">Sign-up for PokerGO special offers &#38; news</label>
    </div> -->
      <div class="form-row">
        <vue-recaptcha
          ref="recaptcha"
          style="margin-top: 20px"
          :sitekey="recaptchakey"
          @verify="setRobot()"
        />
      </div>

      <img src="../assets/loader.gif" class="form-loading" v-if="formloading" />
      <p class="form-success" v-if="formSuccess">Successfully Submitted</p>
      <p class="error" v-if="formerror">Submission not successful! Try again</p>
      <p class="error" v-if="etherror">Please enter a valid wallet address</p>
      <p class="error" v-if="emailerror">Please enter a valid email address</p>
      <button
        v-if="submitButton"
        class="form-submit"
        style="background: rgb(87, 50, 201); color: white"
        @click="submit"
      >
        SUBMIT
      </button>
    </div>
  </div>
</template>
<script>
import states from "../assets/states.js";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  components: {
    VueRecaptcha,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formsubmitted: false,
      ethwallet: "",
      email: "",
      discord: "",
      twitter: "",
      etherror: false,
      emailerror: false,
      formerror: false,
      submitButton: true,
      formloading: false,
      formSuccess: false,
      recaptchakey: process.env.RECAPTCHA,
      headertext: "PokerGO Subscriber Whitelist Form",
      subheadertext:
        "We're excited to offer complimentary whitelist access to all PokerGO subscribers! This will give you the opportunity to have early access to the PokerGO Genesis NFT Collection. Please submit your information below, and come find us on Discord or Twitter if you have any questions. ",
      stateList: states,
      robot: false,
      formclosed: true,
    };
  },
  methods: {
    setRobot(response) {
      console.log(response);
      this.robot = true;
    },
    loading() {
      this.formloading = true;
      // this.$refs.recaptcha.execute();
      this.submitButton = false;
      this.formSuccess = false;
      this.etherror = false;
      this.emailerror = false;
      this.formerror = false;
    },
    success() {
      this.formerror = false;
      this.formloading = false;
      this.formSuccess = true;
      this.submitButton = false;
    },
    error() {
      this.formloading = false;
      this.formSuccess = false;
      this.submitButton = true;
      this.formerror = true;
    },
    async submit() {
      if (!this.robot) {
        return;
      }
      this.loading();
      //await this.$refs.recaptcha.execute();
      // const token = await this.$recaptcha("submit");
      // console.log("token: ", token);
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const ethregex = /^0x[a-fA-F0-9]{40}$/;
      console.log("submission ", this.email, this.ethwallet);
      if (!this.email || !this.email.match(regex)) {
        this.emailerror = true;
        this.formloading = false;
        this.submitButton = true;
        this.formerror = true;
        return;
      } else if (!this.ethwallet || !this.ethwallet.match(ethregex)) {
        this.etherror = true;
        this.formloading = false;
        this.submitButton = true;
        this.formerror = true;
        return;
      }

      this.formsubmitted = true;

      const postStream = {
        email: this.email,
        ethereumid: this.ethwallet,
        discord: this.discord,
        twitter: this.twitter,
        // pokergo: document.getElementById("PokerGO").checked,
        contest_name: process.env.SITE,
        recorded: new Date(),
      };

      console.log("stream: ", postStream);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postStream),
      };
      fetch(
        "https://89026e48f21e8052d6b1ac6891840788.m.pipedream.net/",
        requestOptions
      )
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            this.error();
            return Promise.reject(error);
          }
          this.success();
          this.postId = data.id;
          let subject = "";
          let templateId = "";
          switch (this.site) {
            case "rungood":
              subject = process.env.EMAIL_RUNGOOD_SUBJECT;
              templateId = process.env.EMAIL_RUNGOOD_TEMPLATE_ID;
              break;
            case "hsp":
              subject = process.env.EMAIL_HSP_SUBJECT;
              templateId = process.env.EMAIL_HSP_TEMPLATE_ID;
              break;
          }
          var message = {
            app_id: process.env.ONESIGNAL_APP_ID,
            include_email_tokens: [postStream.email],
            email_subject: subject,
            template_id: templateId,
          };
          //this.sendNotification(message);
        })
        .catch((error) => {
          this.errorMessage = error;
          this.error();
        });
    },
    sendNotification(data) {
      var headers = {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Basic " + process.env.ONESIGNAL_REST_API_KEY,
      };

      var options = {
        host: "onesignal.com",
        port: 443,
        path: "/api/v1/notifications",
        method: "POST",
        headers: headers,
      };

      var https = require("https");
      var req = https.request(options, function (res) {
        res.on("data", function (data) {
          console.log("Response:");
          console.log(JSON.parse(data));
        });
      });
      req.on("error", function (e) {
        console.log("ERROR:");
        console.log(e);
      });
      req.write(JSON.stringify(data));
      req.end();
    },
  },
  mounted() {
    //console.log(this.recaptchakey);
    this.subheadertext = "The PokerGO Genesis NFT Collection whitelist is now closed. For more information on mint and to confirm your whitelist placement, please visit the PokerGO Discord at discord.gg/pokergo";
  },
};
</script>

<style>
body {
  background: #edebfa !important;
  color: black !important;
}
h2,
h3,
p,
span,
small,
a,
label,
form label {
  color: black !important;
}
.form-row{
  justify-content: center;
  margin-bottom: 30px;
}
form label {
  opacity: 0.7 !important;
}
.form-row input {
  border-color: gray;
}
.form-row input {
  color: black;
  max-width: 700px;
  width: 700px;
  box-sizing: border-box;
}
.form-row p {
  width: 400px;
}
.form-row input::placeholder,
.form-row-address-first input::placeholder {
  color: #222222;
}
@media (max-width: 720px) {
  .form-row input {
    width: 100%;
    height: 40px;
  }
}
.error {
  color: red !important;
}
.form-header {
  color: black;
}
.form-submit{
  margin-top: 45px;
}
</style>