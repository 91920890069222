import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import RulesRunGood from "../views/RulesRunGood.vue";
import RulesHSP from "../views/RulesHSP.vue";
import Rules from "../views/Rules.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/rules",
    name: "Rules",
    component: Rules,
  },
  {
    path: "/ruleshsp",
    name: "RulesHSP",
    component: RulesHSP,
  },
  {
    path: "/rulesrungood",
    name: "RulesRunGood",
    component: RulesRunGood,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, from, next) {
  if (to.name === "Rules") {
    if (process.env.SITE === "rungood") {
      next({ name: "RulesRunGood" });
    } else if (process.env.SITE === "hsp") {
      next({ name: "RulesRunGood" });
    }
  }
  next(); // make sure to always call next()!
});

export default router;
