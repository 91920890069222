<template>
  <div class="home" v-bind:class="homeClass">
    <div class="home-logos" v-if="site !== 'NFT_WHITELIST'">
      <router-link to="/"
        ><img class="home-logo" v-bind:src="leftlogo" alt="Logo"
      /></router-link>
      <router-link to="/"
        ><img class="home-logo" src="./assets/pglogo.png" alt="Logo"
      /></router-link>
    </div>
    <div v-else>
      <router-link to="/">
        <img src="./assets/poker-bg.jpeg" style="height:17vw; object-fit:contain" alt="Logo" />
      </router-link>
    </div>
    <router-view></router-view>
    <app-footer />
  </div>
</template>

<script>
import AppFooter from "./views/Footer.vue";
export default {
  components: {
    AppFooter,
  },
  data() {
    return {
      homeClass: "home",
      leftlogo: "",
      site: process.env.SITE,
    };
  },
  mounted() {
    console.log("App Site LOADED:", this.site);
    switch (this.site) {
      case "rungood":
        this.homeClass = "home-rungoodbackground";
        this.leftlogo = require("./assets/rungood_logo.png");
        break;
      case "hsp":
        this.homeClass = "home-hsp";
        this.leftlogo = require("./assets/hsp_logo.png");
        break;
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/index.scss";
* {
  box-sizing: border-box;
}
@media (max-width: 720px) {
  * {
    width: 100%;
  }
}
</style>
